import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Login } from './Login/Login';
import { Header } from '../components/Header/Header';
import { Logout } from './Login/Logout';
import { Container } from 'react-bootstrap';
import { Loader } from '../components/Loader/Loader';
import Api from '../Api';
import { Footer } from '../components/Footer/Footer';
import { NewFooter } from '../components/Footer/NewFooter';
import { Checkout } from './Checkout/NewCheckout';
import { Register } from './Register/Register';
import { Account } from './Account';
import { ListingsIndex } from './Manager/Listings/ListingsIndex';
import { ListingCreate } from './Manager/Listings/ListingCreate';
import { ListingUpdate } from './Manager/Listings/ListingUpdate';
import ListingPricing from './Manager/Listings/ListingPricing';
import ListingAvailability from './Manager/Listings/ListingAvailability';
import { ReservationsIndex } from './Manager/Reservations/ReservationsIndex';
import { ManagerHeader } from '../components/Header/ManagerHeader';
import { Listing } from './Listing/NewListing';
import Fontainebleau from './Listing/Fontainebleau';
import NewHeader from '../components/Header/NewHeader';
import { NewSearch } from './Search/NewSearch';
import { PenthousesShow } from './Search/PenthousesShow';
// import PromoLinksIndex from './Manager/PromoLinks/PromoLinksIndex';
// import PromoLinkCreate from './Manager/PromoLinks/PromoLinkCreate';

export class Routes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };

    this.user = this.user.bind(this);
  }

  user() {
    Api.get('/api/users/me')
      .then((response) => {
        console.log(response.data);
        if (response.data.id) this.setState({ user: response.data });
        else this.setState({ user: false });
      })
      .catch((e) => {
        this.setState({ user: false });
      });
  }

  componentDidMount() {
    this.user();
  }

  render() {
    let user = this.state.user;
    // console.log(props, "props")
    if (user === null) return <Loader />;
    else
      return (
        <Router>
          <Switch>
            {/* Home */}

            {/*<Route exact path="/" render={(props) => (*/}
            {/*    <>*/}
            {/*        <Header user={user} show={"dashboard"}/>*/}
            {/*        <Home/>*/}
            {/*        <Footer/>*/}
            {/*    </>*/}
            {/*)}/>*/}

            <Route
              exact
              path="/manager/listings"
              render={(props) => (
                <>
                  {user.role !== 'admin' ? (
                    <Redirect to={'/denied-403'} />
                  ) : (
                    <div>
                      <ManagerHeader adminArea={true} user={user} show={'dashboard'} />

                      <ListingsIndex user={user} />
                    </div>
                  )}
                </>
              )}
            />

            <Route
              exact
              path="/manager/listings/create/:id?"
              render={(props) => (
                <>
                  {user.role !== 'admin' ? (
                    <Redirect to={'/denied-403'} />
                  ) : (
                    <div>
                      <ManagerHeader adminArea={true} user={user} show={'dashboard'} />
                      <ListingCreate id={props.match.params.id} user={user} />
                    </div>
                  )}
                </>
              )}
            />

            <Route
              exact
              path="/manager/listings/update/:id"
              render={(props) => (
                <>
                  {user.role !== 'admin' ? (
                    <Redirect to={'/denied-403'} />
                  ) : (
                    <div>
                      <ManagerHeader adminArea={true} user={user} show={'dashboard'} />
                      <ListingUpdate id={props.match.params.id} user={user} />
                    </div>
                  )}
                </>
              )}
            />

            <Route
              exact
              path="/manager/listings/:id/pricing"
              render={(props) => (
                <>
                  {user.role !== 'admin' ? (
                    <Redirect to={'/denied-403'} />
                  ) : (
                    <div>
                      <ManagerHeader adminArea={true} user={user} show={'dashboard'} />
                      <ListingPricing id={props.match.params.id} user={user} />
                    </div>
                  )}
                </>
              )}
            />

            <Route
              exact
              path="/manager/listings/:id/availability"
              render={(props) => (
                <>
                  {user.role !== 'admin' ? (
                    <Redirect to={'/denied-403'} />
                  ) : (
                    <div>
                      <ManagerHeader adminArea={true} user={user} show={'dashboard'} />
                      <ListingAvailability id={props.match.params.id} user={user} />
                    </div>
                  )}
                </>
              )}
            />

            {/* <Route exact path="/manager/promo-links" render={(props) => (
                        <>
                          {
                            user.role !== 'admin' ? <Redirect to={"/denied-403"}/> :
                            <div>
                                <Header adminArea={true} user={user} show={"dashboard"} />
                                <PromoLinksIndex user={user}/>
                            </div>
                          }
                        </>
                    )}/>

                    <Route exact path="/manager/promo-links/create" render={(props) => (
                        <>
                          {
                            user.role !== 'admin' ? <Redirect to={"/denied-403"}/> :
                            <div>
                                <Header adminArea={true} user={user} show={"dashboard"} />
                                <PromoLinkCreate user={user}/>
                            </div>
                          }
                        </>
                    )}/> */}

            <Route
              exact
              path="/manager/pending-bookings"
              render={(props) => (
                <>
                  {user.role !== 'admin' ? (
                    <Redirect to={'/denied-403'} />
                  ) : (
                    <div>
                      <ManagerHeader adminArea={true} user={user} show={'dashboard'} />
                      <ReservationsIndex />
                    </div>
                  )}
                </>
              )}
            />

            <Route
              exact
              path="/denied-403"
              render={(props) => (
                <>
                  <Header user={user} show={'dashboard'} />
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="error-template">
                          <h1>Oops! 403</h1>
                          <h2>Acces denied!</h2>
                          <div className="error-details mt-2">Sorry, an error has occured, Requested page not found!</div>
                          <div className="error-actions mt-4">
                            <a href="/" className="btn btn-primary btn-lg">
                              <span className="glyphicon glyphicon-home"></span>
                              Take Me Home
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Footer />
                </>
              )}
            />

            <Route
              exact
              path="/account"
              render={(props) => (
                <>
                  {!user.id ? (
                    <Redirect to={'/login'} />
                  ) : (
                    <>
                      <Header user={user} show={'account'} />
                      <Account user={user} />
                      <Footer />
                    </>
                  )}
                </>
              )}
            />

            <Route
              exact
              path="/login/:token?"
              render={(props) => (
                <>
                  {user.id ? (
                    <Redirect to={'/account'} />
                  ) : (
                    <>
                      <NewHeader user={user} show={'dashboard'} />
                      <Login token={props.match.params.token} user={user} updateFunction={this.user} />
                      <NewFooter />
                    </>
                  )}
                </>
              )}
            />

            <Route
              exact
              path="/register/:token?"
              render={(props) => (
                <>
                  {user.id ? (
                    <Redirect to={'/'} />
                  ) : (
                    <>
                      <NewHeader user={user} show={'dashboard'} />
                      <Register token={props.match.params.token} user={user} updateFunction={this.user} />
                      <NewFooter />
                    </>
                  )}
                </>
              )}
            />

            <Route
              exact
              path="/logout"
              render={(props) => <>{!user.id ? <Redirect to={'/login'} /> : <Logout user={user} updateFunction={this.user} />}</>}
            />

            <Route
              exact
              path="/miami-vacation-rentals"
              render={(props) => {
                const promoCode = new URLSearchParams(props.location.search).get('code');

                if (promoCode) {
                  window.localStorage.setItem('promo', promoCode);
                }

                return (
                  <>
                    <NewHeader />
                    <NewSearch user={user} filterType="rentals" />
                    <NewFooter />
                  </>
                );
              }}
            />

            <Route
              exact
              path="/miami-penthouse"
              render={(props) => {
                const promoCode = new URLSearchParams(props.location.search).get('code');

                if (promoCode) {
                  window.localStorage.setItem('promo', promoCode);
                }

                return (
                  <>
                    <NewHeader />
                    <PenthousesShow user={user} filterType="penthouse" />
                    <NewFooter />
                  </>
                );
              }}
            />

            {/* Old stuff */}
            {/* <Route exact path="/" render={() => <Redirect to="/miami-vacation-rentals" />} /> */}

            {/* New stuff */}
            <Route
              exact
              path="/"
              render={() => {
                return (
                  <>
                    <NewHeader />
                    <NewSearch user={user} />
                    <NewFooter />
                  </>
                );
              }}
            />

            <Route
              exact
              path="/fontainebleau/tresor/penthouse-miami-beach"
              render={() => {
                return <Fontainebleau />;
              }}
            />

            <Route
              exact
              path="/listing/:slug"
              render={(props) => <Redirect to={`/miami-vacation-rentals/listing/${props.match.params.slug}${props.location.search}`} />}
            />

            <Route
              exact
              path="/miami-vacation-rentals/listing/:slug"
              render={(props) => {
                const { id } = props.match.params;
                const { slug } = props.match.params;
                const queryParams = new URLSearchParams(props.location.search);
                const checkIn = queryParams.get('checkIn');
                const checkOut = queryParams.get('checkOut');
                const adults = queryParams.get('adults');
                const children = queryParams.get('children');

                return (
                 <>
                    <div>
                      <NewHeader user={user} show="dashboard" />
                      <Container>
                        <Listing
                          listingId={id}
                          listingSlug={slug}
                          checkIn={checkIn}
                          checkOut={checkOut}
                          adults={adults}
                          children={children}
                        />
                      </Container>
                      <NewFooter />
                    </div>
                  </>
                );
              }}
            />

            <Route
              exact
              path="/listing/:id/fontainebleau/"
              render={() => {
                return <Fontainebleau />;
              }}
            />

            <Route
              exact
              path="/checkout/:listingId"
              render={(props) => (
                <Redirect to={`/miami-vacation-rentals/checkout/${props.match.params.listingId}${props.location.search}`} />
              )}
            />

            <Route
              exact
              path="/miami-vacation-rentals/checkout/:listingId"
              render={(props) => {
                const { listingId } = props.match.params;
                const queryParams = new URLSearchParams(props.location.search);
                const { reservations, bookDisabled } = props.location.state || {};

                return (
                  <>
                    <NewHeader user={user} show="dashboard" />
                    <Checkout
                      user={user}
                      listingId={listingId}
                      queryParams={queryParams}
                      reservations={reservations || []}
                      bookDisabled={bookDisabled || false}
                    />
                    <NewFooter />
                  </>
                );
              }}
            />

            <Route
              exact
              path="/code/:code"
              render={(props) => {
                let code = props.match.params.code;
                window.localStorage.setItem('promo', code);

                return <Redirect to={'/'} />;
              }}
            />

            <Route
              exact
              path="/:show/view/:id/:selectedTab?"
              render={(props) => (
                <>
                  <div className={'bg-light'}>
                    <Header user={user} show={props.match.params.show} filter={props.match.params.filter} />
                    <Container fluid>
                      {/*{props.match.params.show === "reservations" &&*/}
                      {/*    <ReservationDetails id={props.match.params.id}*/}
                      {/*                        selectedTab={props.match.params.selectedTab} user={user}/>}*/}
                    </Container>
                  </div>
                </>
              )}
            />

            <Route
              exact
              path="/:show/:filter?/:selectedTab?"
              render={(props) => (
                <>
                  <div className={'bg-light'}>
                    <Header user={user} show={props.match.params.show} filter={props.match.params.filter} />
                    <Container fluid>
                      {/*{props.match.params.show === "reservations" &&*/}
                      {/*    <ReservationsList filter={props.match.params.filter} user={user}/>}*/}
                    </Container>
                  </div>
                </>
              )}
            />

            <Route>
              <Redirect to={'/'} />
            </Route>
          </Switch>
        </Router>
      );
  }
}
