import {getMonthCalendarData, renderCalendarMonth} from '../calendar';
import {addMonths, differenceInMonths} from 'date-fns';
import {updateClassesPerRange, updateFooterText, updateRangeDates} from './date-range-picker-helper';
import { DateRangePickerSettings, ISelectedRange } from '../../interfaces/date-range-picker';

/**
 * JSOcean Range Date Picker: Mobile Version
 */
class JSORangeDatePickerMobile{

    /**
     * properties
     */
    settings: DateRangePickerSettings;
    onFinishSelection: Function;
    fromText: string;
    toText: string;
    $root: HTMLElement;
    $eventsRoot: HTMLElement;
    selectedRange: ISelectedRange;
    todayDate: Date;
    calendarDate: Date;

    /**
     * init
     * @param {object=} settings
     * @param {{from: Date, to: Date}|null|undefined} selectedRange
     * @param {Function} onFinishSelection
     * @param {string} fromText
     * @param {String} toText
     * @param {HTMLElement=} $eventsRoot - events root is used to dispatch events; if it's undefined, $root is used instead
     */
    constructor(settings: DateRangePickerSettings, selectedRange: ISelectedRange, onFinishSelection: Function, fromText: string, toText: string, $eventsRoot?: HTMLElement) {

        this.settings = settings;
        this.onFinishSelection = onFinishSelection;
        this.fromText = fromText || '';
        this.toText = toText || '';

        this.$root = document.createElement('div');
        this.$root.className = `jso-popup jso-popup-fullscreen ${this.settings.popupClassName}`;
        this.$eventsRoot = $eventsRoot || this.$root;

        // selected dates
        // @ts-ignore
        this.selectedRange = selectedRange ? selectedRange : {
            from: null,
            to: null
        };

        // dates
        this.todayDate = new Date();

        // the following months will be rendered: [todayDate, ..., this.calendarDate, this.setting.mobileMonthsStep number of months ]
        this.calendarDate = this.selectedRange && this.selectedRange.from ? this.selectedRange.from : new Date();
       
        // render view and handle view events
        this.updateView();
    }

    // ---------- RENDER --------------

    /**
     * render view and handle view events
     */
    updateView(){

        // render date picker mobile popup
        this.$root.innerHTML = this.render();
        // update day classes according to the date range
        updateClassesPerRange(this.settings, this.$root, this.selectedRange);

        // update footer text
        updateFooterText(this.settings, this.$root, this.selectedRange, this.fromText, this.toText, this.settings.dayNames3, this.settings.monthNames3);

        // jump the the month that should be visible
        if(this.selectedRange && this.selectedRange.from) {
            this.jumpToMonth(this.selectedRange.from.getMonth(), this.selectedRange.from.getFullYear());
        }

        // init mobile popup events
        this.handlePopupEvents();
    }

    /**
     * render
     */
    render(){

        // count number of months to render
        const monthsCountBetween = Math.abs(differenceInMonths(this.calendarDate, this.todayDate));
        // @ts-ignore
        const monthsCount = monthsCountBetween + this.settings.mobileMonthsStep;
        // generate all the months
        let calendarHTML = this.getMonthsHTML(this.todayDate, monthsCount);
      
        return `
            <div class="jso-popup-box jso-date-range-picker-dropdown-mobile">
                <div class="jso-popup-content-top jso-popup-content-top-mobile">
                    <h4>${this.settings.mobileHeaderText || '&nbsp;'}</h4>
                    <button type="button" class="jso-popup-close-button ${this.settings.popupCloseButtonClass}">&nbsp;</button>
                </div>    
                <div class="jso-date-range-picker-popup-content">
                    ${calendarHTML}
                </div>
                <div class="jso-date-range-picker-popup-footer">
                    <div class="jso-date-range-picker-footer-texts">
                        <div class="jso-date-range-picker-footer-date-texts">
                            <span class="jso-date-range-picker-footer-text jso-date-range-picker-footer-text-from">${this.fromText}</span>
                            <span>-</span>
                            <span class="jso-date-range-picker-footer-text jso-date-range-picker-footer-text-to">${this.toText}</span>
                        </div>
                    </div>
                    <button type="button" class="jso-date-range-picker-popup-done-btn">${this.settings.doneButtonText}</button>
                </div>
            </div>
        `;
    }

    /**
     * get months HTML
     * @param {Date} fromDate
     * @param {number} monthsCount
     * @return {string}
     */
    getMonthsHTML(fromDate: Date, monthsCount: number){

        // generate all the months
        let calendarHTML = '';

        // initialize months enumerator
        let month = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
        for(let i=0; i<monthsCount; i++){

            // get calendar data
            const calendarMonthData = getMonthCalendarData(
                month.getMonth(),
                month.getFullYear(),
                this.todayDate.getDate(),
                this.todayDate.getMonth(),
                this.todayDate.getFullYear(),
                <number>this.settings.weekStart,
                // @ts-ignore
                this.settings.monthNames
            );
            // render calendar month HTML
            calendarHTML += renderCalendarMonth(calendarMonthData, {
                dayNames2: this.settings.dayNames2,
                monthNames: this.settings.monthNames,
                weekStart: this.settings.weekStart
            });

            month = addMonths(month, 1);
        }
        

        return calendarHTML;
    }

    /**
     * add next 'mobileMonthsStep' number months to the view
     */
    addNextMonths(){

        const $calendarsBox = this.$root.querySelector('.jso-date-range-picker-popup-content');

        if(!$calendarsBox) return;
        const calendarHTML = this.getMonthsHTML(this.calendarDate, <number>this.settings.mobileMonthsStep);
        $calendarsBox.insertAdjacentHTML('beforeend', calendarHTML); // IE11+
       
    }

    // ---------- EVENTS --------------

    /**
     * day on click
     * @param {HTMLElement} $day
     */
    handleDayClickEvent($day: HTMLElement){

        const day = Number($day.getAttribute('data-day'));
        const month = Number($day.getAttribute('data-month'));
        const year = Number($day.getAttribute('data-year'));

        // update selected range
        this.updateRangeDates(day, month, year);

        // update day classes according to the date range
        updateClassesPerRange(this.settings, this.$root, this.selectedRange);

        // update footer text
        updateFooterText(this.settings, this.$root, this.selectedRange, this.fromText, this.toText, this.settings.dayNames3, this.settings.monthNames3);
    }

    /**
     * init popup events
     */
    handlePopupEvents(){

        /**
         * prevent close / open when click on popup content
         */
        const $content = this.$root.querySelector('.jso-popup-box');

        if($content){
            $content.addEventListener('click', (evt: Event) => {

                const $target = evt.target as HTMLElement;

                if($target &&
                    ($target.classList.contains('jso-calendar-after-today') ||
                    $target.classList.contains('jso-calendar-today'))){

                    // handle click event
                    this.handleDayClickEvent($target);
                }

                evt.stopPropagation();
            });
        }

        /**
         * handle close button click
         */
        const $close = this.$root.querySelector('.jso-popup-close-button');

        if($close){
            $close.addEventListener('click', evt => {
                this.close();
            });
        }

        /**
         * handle done button click
         */
        const $done = this.$root.querySelector('.jso-date-range-picker-popup-done-btn');
        const elements = document.querySelectorAll('.jso-booking-check-in-out .jso-date-range-picker-popup');

        if($done){
            if(elements.length) {
                // @ts-ignore
                elements[0].style.display = 'none'
            }
            $done.addEventListener('click', evt => {
                this.close();
            });
        }

        /**
         * handle scroll in popup
         */
        const $calendarsBox = this.$root.querySelector('.jso-date-range-picker-popup-content');

        if($calendarsBox){

            $calendarsBox.addEventListener('scroll', evt => {

                const $target = evt.target as HTMLElement;

                // 1 month height factor
                let factor = 0;
                const $month = $target.querySelector('.jso-calendar-month');

                if($month){
                    factor = $month.clientHeight * 2;
                }

                /**
                 * check if scrolled to the bottom:
                 *
                 * clientHeight includes padding
                 * offsetHeight includes padding, scrollBar and borders, doesn't work on IE10 below
                 *
                 * in this case evt.target.scrollHeight and evt.target.offsetHeight doesn't change, as popup is fixed
                 * here evt.target.offsetHeight means the height of 1 "DIV viewport"
                 * evt.target.scrollTop changes, but it height always smaller than DIV height by 1 "DIV viewport"
                 */
                if($target.scrollTop + $target.offsetHeight >= $target.scrollHeight - factor){
                    // update calendar date by months step
                    this.calendarDate = addMonths(this.calendarDate,this.calendarDate.getMonth() >= 8 ? 4 : <number>this.settings.mobileMonthsStep);
                
                    // add next months HTML to the popup
                    this.addNextMonths();
                }
            });
        }
    }

    // -------------- RANGE ----------------------

    /**
     * update selected days range
     * @param {number} day
     * @param {number} month
     * @param {number} year
     */
    updateRangeDates(day: number, month: number, year: number){

        const selectedDate = new Date(year, month, day);
        const res = updateRangeDates(selectedDate, this.selectedRange);

        this.selectedRange = {
            from: res.from,
            // @ts-ignore
            to: res.to
        }

        /*if(res.isReady){

            // close the dropdown and pass dates
            if(this.onFinishSelection) {
                this.onFinishSelection(this.selectedRange);
            }
        }*/
    }

    // ---------- HELPERS -------------

    /**
     * handle popup close
     */
    close(){
        // close the dropdown and pass dates
        if(this.onFinishSelection) {
            this.onFinishSelection(this.selectedRange);
        }
    }

    /**
     * scroll to the given month
     * @param {number} month - from 0 to 11, January is 0, December is 11
     * @param {number} year - four digits, for example 2018
     */
    jumpToMonth(month: number, year: number){

        window.setTimeout(() => {

            // <th colspan="7" class="jso-calendar-month-title" data-month="3" data-year="2020">...</th>
            const $month = this.$root.querySelector(`.jso-calendar-month[data-month="${month}"][data-year="${year}"]`);
            if(!$month || !$month.scrollIntoView) return;

            $month.scrollIntoView();
        }, 0);

    }
}

export default JSORangeDatePickerMobile;