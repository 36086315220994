import React, {Component} from 'react';
import { Col, Container, Form, Row} from "react-bootstrap";
import {Datepicker} from "../../components/Forms/Datepicker/SearchDatePicker";
import {SearchResults} from "../../components/Lists/SearchResults";
import debounce from "lodash/debounce";
import Api from "../../Api";
import {toast} from "react-hot-toast";
import './NewSearch.scss';
import {Loader} from "../../components/Loader/Loader";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet-async';
import ListingNotFoundWarning from './components/ListingNotFoundWarning';
import {ReactComponent as  SortArrows} from '../../assets/icons/sortArrows.svg';
import { PENTHOUSE_IDS } from '../Listing/Fontainebleau/constants';

export class NewSearch
    extends Component {


    constructor(props) {
        super(props)
        const queryParams = new URLSearchParams(window.location.search);
        let orderByDir       = queryParams.get('orderByDir');
        let storedSort    = localStorage.getItem("sort") || "desc";
        this.state = {
            tableData:               [],
            formData:                [],
            page:                    1,
            pageSize:                100,
            totalSize:               0,
            orderByCol:              "listing_exceptions_sum_price",
            orderByDir:              orderByDir ?? storedSort,
            showModal:               false,
            showModalData:           [],
            showSearch:              false,
            search:                  null,
            redirectLink:            null,
            bookingFormEl:           null,
            showListingDetailsModal: false,
            showListingDetailsData:  [],
            selectedCity:            '',
            selectedTab:              1
        }

        this.fetch                     = this.fetch.bind(this);
        this.handleSearch              = debounce(this.handleSearch.bind(this), 500);
        this.handleSortChange          = this.handleSortChange.bind(this);
        this.updateBookingFormEl       = this.updateBookingFormEl.bind(this);
        this.toggleListingDetailsModal = this.toggleListingDetailsModal.bind(this);

    }

    updateBookingFormEl(
        el,
        city
    ) {

        this.setState({
                          bookingFormEl: el
                      })
        const fields = el.bookingForm.getValue()

        fields.push({
                        name:  "city",
                        value: city,
                    })

        this.setState({city: city})

        this.fetch(fields)
    }

    fetch(inputs) {

        this.setState({
                          loading: true
                      });

        let {
                filter,
                selectedTab
            } = this.props;
        let {
                page,
                pageSize,
                search
            } = this.state;

        let axiosUrl = '/api/listings/get_available?orderByCol=' + this.state.orderByCol + '&orderByDir=' + this.state.orderByDir + '&page_size=' + pageSize + '&page=' + page;
        axiosUrl += '&exact[status]=' + (
            selectedTab === "archived" ? "archived" : "active"
        );
        if (filter) axiosUrl += '&exact[rental_type]=' + filter;
        if (search) axiosUrl += '&search=' + search;

        if (inputs) {

            let checkIn;
            let checkOut;
            let adults;
            let children;
            let city;

            for (
                let i = 0;
                i < inputs.length;
                i++
            ) {
                var value = inputs[i].value;
                if (this.state.selectedTab === 1 && inputs[i].name === "from_date") {
                    checkIn = value;
                    axiosUrl += '&checkIn=' + checkIn;
                }
                if (this.state.selectedTab === 2 && inputs[i].name === "from_date") {
                    checkIn = value;
                    const checkInDate = new Date(checkIn);
                    checkInDate.setMonth(checkInDate.getMonth() + 1);
                    checkOut = checkInDate.toISOString().split('T')[0]
                    axiosUrl += `&checkIn=${checkIn}&checkOut=${checkOut}`
                }
                if (this.state.selectedTab === 1 && inputs[i].name === "to_date") {
                    checkOut = value;
                    axiosUrl += '&checkOut=' + checkOut;
                }
                if (this.state.selectedTab === 2 && inputs[i].name === "to_date") {
                    const checkInDate = new Date(checkIn);
                    checkInDate.setMonth(checkInDate.getMonth() + 1);
                    checkOut = checkInDate.toISOString().split('T')[0]
                    axiosUrl += '&checkOut=' + checkOut;
                }
                if (inputs[i].name === "adults") {
                    adults = value || 2;
                    axiosUrl += '&adults=' + adults;
                }
                if (inputs[i].name === "children") {
                    children = value;
                    axiosUrl += '&children=' + children;
                }
                if (inputs[i].name === "city") {
                    city = value;
                    axiosUrl += '&city=' + city;
                }
            }

            this.setState({
                              formData:     {
                                  checkIn:  checkIn,
                                  checkOut: checkOut,
                                  adults:   adults || 2,
                                  children: children,
                                  city:     city
                              },
                              selectedCity: city
                          });

            window.history.replaceState(null, null,
                                        "?checkIn=" + checkIn + "&checkOut=" + checkOut + "&adults=" + adults + "&children=" + children + "&city=" + city + window.location.hash
            );

        }

        // Make a request for a user with a given ID
        Api.get(axiosUrl)
           .then(response => {
               // handle success
               this.setState({
                                 tableData: response.data.data,
                                 totalSize: response.data.total,
                                 loading:   false
                             }, () => {

                   // //   If hash, open listing.
              

               });


           })
           .catch(function (error) {
               // handle error
               toast.error(error.response.data.message);
               console.log(error);
           })
    }

    handleSearch(event) {
        let value = event.target.value;
        this.setState({
                          search: value,
                          page:   1
                      }, () => {
            this.fetch();
        });

    }

    handleSortChange(event) {

        let value = event.target.value;
        localStorage.setItem("sort", value.split("-")[1])
        this.setState({
                          orderByCol: value.split("-")[0],
                          orderByDir: value.split("-")[1]
                      }, () => {
            const fields = this.state.bookingFormEl.bookingForm.getValue()
            fields.push({
                            name:  "city",
                            value: this.state.selectedCity
                        })
            this.fetch(fields);
        });
    }

    toggleListingDetailsModal(
        event = null,
        id    = null
    ) {

        const {formData} = this.state;

        var newData = [];

        if (event && event.currentTarget) {
            id = (
                event.currentTarget.getAttribute('data-id') ? event.currentTarget.getAttribute(
                    'data-id') : event.currentTarget.getAttribute('id')
            );
        }

        for (const key in
            this.state.tableData) {

            let thisRow = this.state.tableData[key];
            if (thisRow.id === id) {
                newData = thisRow;
            }

        }

        this.setState({
                          showListingDetailsData:  newData,
                          showListingDetailsModal: !this.state.showListingDetailsModal
                      });

        window.history.replaceState(null, null,
                                    "?checkIn=" + formData.checkIn + "&checkOut=" + formData.checkOut + "&adults=" + formData.adults + "&children=" + formData.children + "#" + (
                                                    id ? id : ""
                                                )
        );

    }

    componentDidMount() {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        window.fbq('track', 'PageView');
    }

    filterTableData() {
        const { filterType } = this.props;
        const { tableData } = this.state;

        if(!filterType) {
            return tableData;
        }

        if (filterType === 'penthouse') {
            return tableData.filter(item => PENTHOUSE_IDS.includes(item.airbnb_id));
        } else if (filterType === 'rentals') {
            return tableData.filter(item => !PENTHOUSE_IDS.includes(item.airbnb_id));
        }
    }

    render() {
        const filteredTableData = this.filterTableData();

        return <>
          <Helmet>
            <title>Book Now - Hotel Search</title>
            <meta
              name="description"
              content={`Book your hotel now${this.state.formData.checkIn && this.state.formData.checkOut ? ` from ${this.state.formData.checkIn} to ${this.state.formData.checkOut}` : ''}. Find the best deals and enjoy your stay.`}
            />
            <meta
              name="keywords"
              content={`Book now, hotel booking, ${this.state.formData.checkIn && this.state.formData.checkOut ? `${this.state.formData.checkIn} - ${this.state.formData.checkOut}` : 'hotel availability'}`}
            />
            <meta property="og:url" content={window.location.href} />
          </Helmet>
          <Container className="new-search-container">
                <Row>
                    <Col className="heading-bc">
                        <div className="breadcrumbs breadcrumbs_search_page">
                            <Breadcrumbs children={[
                                {
                                    text: 'Search Listing',
                                    url:  '/'
                                }
                            ]}/>
                        </div>
                        <h1 className={"page-title"}>Book Now</h1>
                    </Col>
                </Row>

               <div className='tabsDate'>
                    <div className='tabs'>
                        <div className={this.state.selectedTab === 1 ? "tab-title-active" : 'tab-title'} onClick={() => this.setState({ selectedTab: 1 })}>VACATION RENTALS</div>
                        <div className={this.state.selectedTab === 2 ? "tab-title-active" : 'tab-title'} onClick={() => this.setState({ selectedTab: 2 })}>LONG-TERM RENTALS</div>
                    </div>
                    <div className="date-picker">
                        {this?.state?.selectedTab === 1 &&  <Datepicker
                            handleInputChange={this.handleInputChange}
                            updateBookingFormEl={this.updateBookingFormEl}
                            search={this.fetch}
                        />}
                        {this?.state?.selectedTab === 2 &&  <Datepicker
                            isMonth={true}
                            handleInputChange={this.handleInputChange}
                            updateBookingFormEl={this.updateBookingFormEl}
                            search={this.fetch}
                        />}
                    </div>
               </div>

                <div className="my-4"/>

                <Row>

                    <Col className={"text-blue listings-available d-flex align-items-center"}>
                        <span className={"me-2"}>{filteredTableData.length}</span> listings available

                        <div className={"d-md-none my-5"}/>

                    </Col>
                    <Col xs={12} md={"auto"}>
                    <span className='sort-arrows'>
                        <SortArrows />
                    </span>
                        <Form.Select onChange={this.handleSortChange}  className="select-price-sorting"
                            value={
                                this.state.orderByCol + '-' + this.state.orderByDir
                            }
                        >
                            <option value={"listing_exceptions_sum_price-asc"}>Price per night: low to high</option>
                            <option value={"listing_exceptions_sum_price-desc"}>Price per night: high to low</option>
                            <option value={"square_feet-asc"}>Square feet: low to high</option>
                            <option value={"square_feet-desc"}>Square feet: high to low</option>
                        </Form.Select>
                    </Col>

                </Row>

                <div className="my-4"/>

                {this.state.loading && <Loader/>}
                {!this.state.loading && !filteredTableData.length && 
                    <ListingNotFoundWarning />
                }
                {filteredTableData.length > 0 && <SearchResults
                    checkIn={this.state.formData.checkIn}
                    checkOut={this.state.formData.checkOut}
                    adults={this.state.formData.adults}
                    children={this.state.formData.children}
                    tableData={filteredTableData}
                    toggleFunction={this.toggleListingDetailsModal}
                    user={this.props.user}
                />}

            </Container>
        </>
    }

}