import React, { Component } from 'react';
import { Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Row, Spinner } from 'react-bootstrap';
import Api from '../../Api';
import { Link, Redirect } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import '../../assets/styles/LoginRegister.scss';
import { ReactComponent as DangerRed } from '../../assets/icons/danger-red.svg';

export class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: [],
      formErrors: [],
      formProcessing: false,
      formSuccess: false,
      show2fa: false,
      showResetPassword: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleResetPassword = this.toggleResetPassword.bind(this);
  }

  toggleResetPassword() {
    this.setState({ showResetPassword: !this.state.showResetPassword });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let { formData = [], formErrors = [], data = [] } = this.state;

    formData[name] = value;
    formErrors[name] = null;
    data[name] = value;

    this.setState({
      formData: formData,
      formErrors: formErrors,
      data: data,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let formData = new FormData();
    for (const key in this.state.formData) formData.append(key, this.state.formData[key]);
    this.setState({ formProcessing: true });

    Api.post('/register', formData)
      .then((response) => {
        toast.success('You have successfully registered. You may now log in.', 
          { 
            style: {
              marginTop: '50px'
            },  
          });
        this.setState({ formSuccess: true });
      })
      .catch((error) => {
        // handle error
        console.log(error);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message,
            { 
              style: {
                marginTop: '50px'
              },  
            }
          );
          this.setState({
            formErrors: error.response.data.errors,
          });
        }
      })
      .finally(() => {
        this.setState({ formProcessing: false });
      });
  }

  render() {
    if (this.props.user) {
      return <Redirect to={'/'} />;
    } else if (this.state.formSuccess) {
      return <Redirect to={'/login'} />;
    } else {
      return (
        <Container className="loginRegisterMainContainer">
          <Row>
            <Col>
              <h1 className={'loginRegisterTitle'}>Sign Up</h1>
            </Col>
          </Row>

          <Row className="loginRegisterPage m-auto">
            <Col md={12} className={'p-0'}>
              <div className="loginFormContainer align-middle">
                <p className={'logInText'}>Please enter your account information below.</p>
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col>
                      <FormGroup className="emailContainer">
                        <FormLabel className="label">Email</FormLabel>
                        <FormControl
                          type="email"
                          name="email"
                          className="input"
                          required
                          isInvalid={this.state.formErrors && this.state.formErrors.email}
                          placeholder="Enter email"
                          onChange={this.handleInputChange}
                        />
                        {this.state.formErrors && this.state.formErrors.email && (
                          <div className="errorMsg">
                            <span>{<DangerRed />}</span>
                            {this.state.formErrors.email}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="inputContainer inputContainerRegister">
                    <Col>
                      <FormGroup>
                        <FormLabel className="label">First Name</FormLabel>
                        <FormControl
                          required
                          type="text"
                          name="first_name"
                          className="input"
                          isInvalid={this.state.formErrors && this.state.formErrors.first_name}
                          placeholder="First name"
                          onChange={this.handleInputChange}
                        />
                        {this.state.formErrors && this.state.formErrors.first_name && (
                          <div className="errorMsg">
                            <span>{<DangerRed />}</span>
                            {this.state.formErrors.first_name}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <FormLabel className="label">Last Name</FormLabel>
                        <FormControl
                          required
                          type="text"
                          name="last_name"
                          className="input"
                          isInvalid={this.state.formErrors && this.state.formErrors.last_name}
                          placeholder="Last name"
                          onChange={this.handleInputChange}
                        />
                        {this.state.formErrors && this.state.formErrors.last_name && (
                          <div className="errorMsg">
                            <span>{<DangerRed />}</span>
                            {this.state.formErrors.last_name}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="inputContainer inputContainerRegister">
                    <Col>
                      <FormGroup>
                        <FormLabel className="label">Password</FormLabel>
                        <FormControl
                          required
                          type="password"
                          name="password"
                          className="input"
                          isInvalid={this.state.formErrors && this.state.formErrors.password}
                          placeholder="Enter password"
                          onChange={this.handleInputChange}
                        />
                        {this.state.formErrors && this.state.formErrors.password && (
                          <div className="errorMsg">
                            <span>{<DangerRed />}</span>
                            {this.state.formErrors.password}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <FormLabel className="label">Confirm Password</FormLabel>
                        <FormControl
                          required
                          type="password"
                          name="password_confirmation"
                          className="input"
                          isInvalid={this.state.formErrors && this.state.formErrors.confirm_password}
                          placeholder="Confirm password"
                          onChange={this.handleInputChange}
                        />
                        {this.state.formErrors && this.state.formErrors.confirm_password && (
                          <div className="errorMsg">
                            <span>{<DangerRed />}</span>
                            {this.state.formErrors.confirm_password}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="buttonsContainer">
                    <Col>
                      <Button size="md" variant="primary" type="submit" className="button login" disabled={this.state.formProcessing}>
                        {this.state.formProcessing && this.props.token ? (
                          <>
                            <Spinner size={'sm'} className={'me-2'} animation={'border'} /> Signing Up
                          </>
                        ) : this.state.formProcessing ? (
                          <>
                            <Spinner size={'sm'} className={'me-2'} animation={'border'} /> Signing Up
                          </>
                        ) : (
                          'Sign Up'
                        )}
                      </Button>

                      <Link to={'/login'} className={'button signup'}>
                        Log In
                      </Link>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      );
    }
  }
}
